import Logger from 'js-logger';
import Vue from 'vue';

export function routerNavigate(this: Vue, link: string): void {
  if (link.startsWith('#')) {
    if (link.includes(this.$route.name as string)) {
      this.$vuetify.goTo(link);
      return;
    }
    const name = link.replace('#', '').split('__')[0];
    this.$router.push({
      name,
      hash: link
    }).catch((e: Error) => Logger.info(e.message));
    return;
  }
  this.$router.push({ name: link })
    .catch((e: Error) => Logger.info(e.message));
}
