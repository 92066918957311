/*
 * Copyright ©  2018 - 2050 EasyLinkz Limited.
 *
 * All rights reserved.
 *
 * Email: super@easylinkz.com
 *
 * Licensed under the proprietary license.
 *
 * The source code contained herein is, and remains the property of EasyLinkz Limited.
 *
 * Dissemination, reproduction or other use of this code is strictly forbidden unless prior written permission
 * is obtained from EasyLinkz Limited.
 *
 * This software is provided by the author “as is” and any express or implied warranties, including,
 * but not limited to, the implied warranties of MERCHANTABILITY and FITNESS FOR A PARTICULAR PURPOSE are disclaimed.
 * In no event shall the author be liable for any direct, indirect, incidental, special, exemplary,
 * or consequential damages (including, but not limited to, procurement of substitute goods or services,
 * loss of use, data, or profits, or business interruption) however caused and on any theory of liability,
 * whether in contract, strict liability, or tort (including negligence or otherwise)
 * arising in any way out of the use of this software, even if advised of the possibility of such damage.
 *
 * You should have received a copy of the EasyLinkz Limited licence along with this program,
 * if not, write to the super@easylinkz.com.
 *
 */

import {HttpClient} from '@/services/http.service';
import {LanguageInterface} from '@/shared/interfaces/store/ui/language.interface';
import {MenuInterface} from '@/shared/interfaces/store/ui/menu.interface';
import {UiInterface} from '@/shared/interfaces/store/ui/ui.interface';
import store from '@/store';
import {Service} from 'vue-typedi';
import {getModule, Module, MutationAction, VuexModule} from 'vuex-module-decorators';
import {TRoutes} from '@/shared/enums/routes.enum';

@Service()
@Module({
  dynamic: true,
  name: 'UiConfig',
  store,
  namespaced: true
})
export class UiConfig extends VuexModule {

  languages: LanguageInterface[] = [];

  logo = '';

  logoContrast = '';

  copyright = '';

  signUpLink = '';

  menu: MenuInterface[] = [
    {
      title: 'ps.general.menu.home',
      link: TRoutes.Home,
      children: []
    },
    {
      title: 'ps.general.menu.product-line',
      link: TRoutes.ProductLine,
      children: []
    },
    {
      title: 'ps.general.menu.connected-suppliers',
      link: '#ProductLine__connected-suppliers',
      children: []
    },
    // {
    //   title: 'ps.general.menu.deal-lifecycle',
    //   link: '#Home__lifecycle-section',
    //   children: []
    // },
    {
      title: 'ps.general.menu.contacts',
      link: '#Home__contact-us-section',
      children: []
    }
  ];

  footerMenu: MenuInterface[] = [
    // {
    //   title: 'ps.general.menu.about-company',
    //   link: '#Home__about-us-section',
    // },
    {
      title: 'ps.general.menu.product-line',
      link: TRoutes.ProductLine
    },
    {
      title: 'ps.general.menu.connected-suppliers',
      link: '#ProductLine__connected-suppliers',
      children: []
    },
    // {
    //   title: 'ps.general.menu.deal-lifecycle',
    //   link: '#Home__lifecycle-section'
    // },
    {
      title: 'ps.general.menu.deal-terms',
      link: 'https://crazyllama.com/crazyllama-platform-terms-of-service',
      blank: true
    },
    {
      title: 'ps.general.menu.deal-privacy',
      link: 'https://crazyllama.com/crazyllama-platform-privacy-policy',
      blank: true
    }
  ];

  @MutationAction({mutate: ['languages', 'logo', 'logoContrast', 'signUpLink']})
  async getConfig() {
    return HttpClient.get<UiInterface>('/ui-config');
  }

}

export const UiConfigStore = getModule(UiConfig);
