














































































import {UiConfigStore} from '@/store/modules/ui-config';
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {DictionariesStore} from '@/store/modules/dictionaries';
import MenuItem from '@/components/common/MenuItem.vue';
import LanguageSelector from '@/components/common/LanguageSelector.vue';
import {config} from '@/shared/config';
import {RawLocation} from 'vue-router';
import Logger from 'js-logger';
import SignUpButton from '@/components/common/SignUpButton.vue';
import { routerNavigate } from '@/services/router';

@Component({
  name: 'App',
  components: {
    SignUpButton,
    LanguageSelector,
    MobileMenu: () => import(/* webpackChunkName: 'mobile-menu' */ '@/components/common/MobileMenu.vue'),
    MenuItem
  }
})
export default class App extends Vue {

  uiConfig = UiConfigStore;

  get signUpLink(): string {
    return this.uiConfig.signUpLink;
  }

  async mounted() {
    await DictionariesStore.getDictionaries();
    await UiConfigStore.getConfig();
    if (!UiConfigStore.languages.some(language => language.value === this.$route.params.lang)) {
      this.changeLanguage(config.defaultLanguage);
    }
  }

  navigate(link: string): void {
    routerNavigate.apply(this, [link]);
  }

  changeLanguage(lang: string): void {
    this._navigate({
      name: this.$route.name as string,
      params: {lang}
    });
  }

  private _navigate(location: RawLocation): void {
    this.$router.push(location)
      .catch((e: Error) => Logger.info(e.message));
  }

}
