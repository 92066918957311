/*
 * Copyright ©  2018 - 2050 EasyLinkz Limited.
 *
 * All rights reserved.
 *
 * Email: super@easylinkz.com
 *
 * Licensed under the proprietary license.
 *
 * The source code contained herein is, and remains the property of EasyLinkz Limited.
 *
 * Dissemination, reproduction or other use of this code is strictly forbidden unless prior written permission
 * is obtained from EasyLinkz Limited.
 *
 * This software is provided by the author “as is” and any express or implied warranties, including,
 * but not limited to, the implied warranties of MERCHANTABILITY and FITNESS FOR A PARTICULAR PURPOSE are disclaimed.
 * In no event shall the author be liable for any direct, indirect, incidental, special, exemplary,
 * or consequential damages (including, but not limited to, procurement of substitute goods or services,
 * loss of use, data, or profits, or business interruption) however caused and on any theory of liability,
 * whether in contract, strict liability, or tort (including negligence or otherwise)
 * arising in any way out of the use of this software, even if advised of the possibility of such damage.
 *
 * You should have received a copy of the EasyLinkz Limited licence along with this program,
 * if not, write to the super@easylinkz.com.
 *
 */

import {HttpClient} from '@/services/http.service';
import {SelectInterface} from '@/shared/interfaces/common/select.interface';
import {DictionariesInterface} from '@/shared/interfaces/store/dictionaries/dictionaries.interface';
import {getModule, Module, MutationAction, VuexModule} from 'vuex-module-decorators';
import store from '@/store';

@Module({
  name: 'Dictionaries',
  dynamic: true,
  namespaced: true,
  store
})
export class Dictionaries extends VuexModule {

  supplierTypes: SelectInterface[] = [];

  integrationTypes: SelectInterface[] = [];

  settlements: SelectInterface[] = [];

  formsOfPayment: SelectInterface[] = [];

  features: SelectInterface[] = [];

  benefits: SelectInterface[] = [];

  @MutationAction({
    mutate: ['supplierTypes', 'integrationTypes', 'settlements', 'formsOfPayment', 'features', 'benefits']
  })
  async getDictionaries(): Promise<DictionariesInterface> {
    const dictionaries = [
      await HttpClient.get<SelectInterface[]>('/supplier-types'),
      await HttpClient.get<SelectInterface[]>('/integration-types'),
      await HttpClient.get<SelectInterface[]>('/settlements'),
      await HttpClient.get<SelectInterface[]>('/form-of-payments'),
      await HttpClient.get<SelectInterface[]>('/supplier-features'),
      await HttpClient.get<SelectInterface[]>('/benefits-list-items')
    ];
    const [supplierTypes, integrationTypes, settlements, formsOfPayment, features, benefits] = await Promise.all(dictionaries);
    return {
      supplierTypes,
      integrationTypes,
      settlements,
      formsOfPayment,
      features,
      benefits
    };
  }

}

export const DictionariesStore = getModule(Dictionaries);
