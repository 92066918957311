import router from '@/router';
import { environment } from '@/environment';
import { VueGtmUseOptions } from '@gtm-support/vue2-gtm/src';

const options: VueGtmUseOptions = {
  id: environment.gtmId,
  debug: environment.gtmDebug,
  vueRouter: router
};

export default options;
