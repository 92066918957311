/*
 * Copyright ©  2018 - 2050 EasyLinkz Limited.
 *
 * All rights reserved.
 *
 * Email: super@easylinkz.com
 *
 * Licensed under the proprietary license.
 *
 * The source code contained herein is, and remains the property of EasyLinkz Limited.
 *
 * Dissemination, reproduction or other use of this code is strictly forbidden unless prior written permission
 * is obtained from EasyLinkz Limited.
 *
 * This software is provided by the author “as is” and any express or implied warranties, including,
 * but not limited to, the implied warranties of MERCHANTABILITY and FITNESS FOR A PARTICULAR PURPOSE are disclaimed.
 * In no event shall the author be liable for any direct, indirect, incidental, special, exemplary,
 * or consequential damages (including, but not limited to, procurement of substitute goods or services,
 * loss of use, data, or profits, or business interruption) however caused and on any theory of liability,
 * whether in contract, strict liability, or tort (including negligence or otherwise)
 * arising in any way out of the use of this software, even if advised of the possibility of such damage.
 *
 * You should have received a copy of the EasyLinkz Limited licence along with this program,
 * if not, write to the super@easylinkz.com.
 *
 */

import { environment } from '@/environment';
import { I18nFormatter } from '@/i18n/i18n-formatter';
import { Http, HttpClient } from '@/services/http.service';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { config } from '@/shared/config';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: config.defaultLanguage,
  fallbackLocale: config.defaultLanguage,
  silentTranslationWarn: true,
  formatter: new I18nFormatter('en')
});

const loadedLanguages: string[] = [];

function setI18nLanguage(lang: string): string {
  i18n.locale = lang;
  Http.defaults.headers.common['Accept-Language'] = lang;
  const html = document.querySelector('html') as HTMLHtmlElement;
  if (html) {
    html.setAttribute('lang', lang);
  }
  return lang;
}

export function loadLanguageAsync(lang: string): Promise<string> {
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  return HttpClient.get<Record<string, string>>(`${environment.url}/translates/language/${lang}`).then(
    messages => {
      i18n.setLocaleMessage(lang, messages);
      loadedLanguages.push(lang);
      return setI18nLanguage(lang);
    }
  );
}
