































import Vue from 'vue';
import {Component, Emit} from 'vue-property-decorator';
import {LanguageInterface} from '@/shared/interfaces/store/ui/language.interface';
import {UiConfigStore} from '@/store/modules/ui-config';

@Component
export default class LanguageSelector extends Vue {

  @Emit()
  changeLanguage(lang: string): string {
    return lang;
  }

  get languages(): LanguageInterface[] {
    return UiConfigStore.languages;
  }

  get currentLanguage(): LanguageInterface | undefined {
    return this.languages.find(lang => lang.value === this.$route.params.lang);
  }

}
