/*
 * Copyright ©  2018 - 2050 EasyLinkz Limited.
 *
 * All rights reserved.
 *
 * Email: super@easylinkz.com
 *
 * Licensed under the proprietary license.
 *
 * The source code contained herein is, and remains the property of EasyLinkz Limited.
 *
 * Dissemination, reproduction or other use of this code is strictly forbidden unless prior written permission
 * is obtained from EasyLinkz Limited.
 *
 * This software is provided by the author “as is” and any express or implied warranties, including,
 * but not limited to, the implied warranties of MERCHANTABILITY and FITNESS FOR A PARTICULAR PURPOSE are disclaimed.
 * In no event shall the author be liable for any direct, indirect, incidental, special, exemplary,
 * or consequential damages (including, but not limited to, procurement of substitute goods or services,
 * loss of use, data, or profits, or business interruption) however caused and on any theory of liability,
 * whether in contract, strict liability, or tort (including negligence or otherwise)
 * arising in any way out of the use of this software, even if advised of the possibility of such damage.
 *
 * You should have received a copy of the EasyLinkz Limited licence along with this program,
 * if not, write to the super@easylinkz.com.
 *
 */

import {loadLanguageAsync} from '@/plugins/i18n';
import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import goTo from 'vuetify/lib/services/goto';
import {VuetifyGoToTarget} from 'vuetify/types/services/goto';
import {ProductLineStore} from '@/store/modules/product-line';
import {config} from '@/shared/config';
import Root from '@/components/Root.vue';
import {TRoutes} from '@/shared/enums/routes.enum';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/:lang',
    component: Root,
    children: [
      {
        path: '',
        name: TRoutes.Home,
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
      },
      {
        path: 'product-line',
        name: TRoutes.ProductLine,
        component: () => import(/* webpackChunkName: "product-line" */ '../views/ProductLine.vue'),
        beforeEnter: (to, from, next) => {
          ProductLineStore.getProductLine().then(() => next());
        }
      }
    ]
  },
  {
    path: '',
    redirect: `/${config.defaultLanguage}`
  },
  {
    path: '*',
    redirect: `/${config.defaultLanguage}`
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo: VuetifyGoToTarget = 0;
    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }
    goTo(scrollTo, {duration: 200});
  },
  routes
});

router.beforeEach((to, from, next) => {
  const lang = to.params.lang || config.defaultLanguage;
  loadLanguageAsync(lang).then(() => next());
});

export default router;
