









import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component
export default class SignUpButton extends Vue {
  @Prop({
    default: ''
  })
  link: string;

  @Prop({
    default: false
  })
  block: boolean;

  open(): void {
    window.open(this.link, '_blank');
  }
};
