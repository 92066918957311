/*
 * Copyright ©  2018 - 2050 EasyLinkz Limited.
 *
 * All rights reserved.
 *
 * Email: super@easylinkz.com
 *
 * Licensed under the proprietary license.
 *
 * The source code contained herein is, and remains the property of EasyLinkz Limited.
 *
 * Dissemination, reproduction or other use of this code is strictly forbidden unless prior written permission
 * is obtained from EasyLinkz Limited.
 *
 * This software is provided by the author “as is” and any express or implied warranties, including,
 * but not limited to, the implied warranties of MERCHANTABILITY and FITNESS FOR A PARTICULAR PURPOSE are disclaimed.
 * In no event shall the author be liable for any direct, indirect, incidental, special, exemplary,
 * or consequential damages (including, but not limited to, procurement of substitute goods or services,
 * loss of use, data, or profits, or business interruption) however caused and on any theory of liability,
 * whether in contract, strict liability, or tort (including negligence or otherwise)
 * arising in any way out of the use of this software, even if advised of the possibility of such damage.
 *
 * You should have received a copy of the EasyLinkz Limited licence along with this program,
 * if not, write to the super@easylinkz.com.
 *
 */

import {MarkdownRenderer} from '@/i18n/markdown.renderer';
import {Marked} from 'marked-ts';
import MessageFormat from 'messageformat';
import {Formatter, Values} from 'vue-i18n';

Marked.setOptions({
  renderer: new MarkdownRenderer(),
  sanitize: true,
  escape: html => html
    .replace(/&#39;/g, '\'')
  //   .replace(/%20/g, ' ')
  //   .replace(/%0A/g, '\n')
  //   .replace(/&lt;/g, '<'),
});

export class I18nFormatter implements Formatter {

  private readonly _locale: string;
  private readonly _formatter: MessageFormat;
  private readonly _caches: Record<string, (values: Values) => string> = Object.create(null);

  constructor(lang: string) {
    this._locale = lang;
    this._formatter = new MessageFormat(this._locale);
  }

  interpolate(message: string, values: Values): unknown[] | null {
    let fn = this._caches[message];
    if (!fn) {
      fn = this._formatter.compile(message, this._locale);
      this._caches[message] = fn;
    }
    return [Marked.parse(fn(values))];
  }

}
