

































import Vue from 'vue';
import {Component, Emit, Prop} from 'vue-property-decorator';
import {MenuInterface} from '@/shared/interfaces/store/ui/menu.interface';

@Component
export default class MenuItem extends Vue {

  @Prop()
  item: MenuInterface;

  @Emit()
  navigate(link: string): string {
    return link;
  }
}
